import React from "react"
import PropTypes from "prop-types"
import he from "he"

import Link from "../utils/link"

import Logo from "./logo"
import Sun from "./sun"
import Moon from "./moon"
import Bars from "./bars"
import Times from "./times"

const Header = ({ isDark, toggleIsDark, location, projects }) => {
  const pathname = typeof window !== "undefined" && location.pathname

  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isLinkExpanded, setIsLinkExpanded] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const toggleIsLinkExpanded = () => {
    setIsLinkExpanded(!isLinkExpanded)
  }

  React.useEffect(() => {
    setIsLinkExpanded(false)
    setIsExpanded(false)
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScrolled(window.pageYOffset > 1)
      );
    }
  }, [pathname])

  return (
    <header className={`${scrolled ? 'header header--scrolled' : 'header'}`}>
      <div className="header__container">
        <div className="header__content">
          <div className="header__brand">
            <Link to="/" className="logo">
              <Logo color={isDark ? "white" : "black"} />
            </Link>
          </div>
          <div className={`header__links ${isExpanded ? "is-expanded" : ""}`}>
            <div className="header__links__link">
              <Link className="link" to="/work/">
                Work
              </Link>
            </div>
            <div className="header__links__link">
              <Link className="link" to="/about-us/">
                About
              </Link>
            </div>
            <div className="header__links__link">
              <Link className="link" to="/journal/">
                Journal
              </Link>
            </div>
            <div className="header__links__link">
              <Link className="link" to="/contact/">
                Contact
              </Link>
            </div>
          </div>
          <div
            className={`header__hamburger ${isExpanded ? "is-expanded" : ""}`}
          >
            {isExpanded ? (
              <Times
                color={isDark ? "white" : "black"}
                onClick={toggleIsExpanded}
              />
            ) : (
              <Bars
                color={isDark ? "white" : "black"}
                onClick={toggleIsExpanded}
              />
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  isDark: PropTypes.bool.isRequired,
  toggleIsDark: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  projects: PropTypes.object.isRequired,
}

export default Header
