import React, {Component} from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'

class NewsletterForm extends Component {

  state = {
    result: '',
    msg: ''
  };

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  // 2. via `async/await`
  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email)
    // I recommend setting `result` to React state
    // but you can do whatever you want
    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {
    let props = {
      ref: 'form',
      name: 'subscribe',
      className: 'form',
      onSubmit: this._handleSubmit
    };
    if (this.state.result === 'success') {
      return (
        <div className='form__thanks'>
          <p className="result" dangerouslySetInnerHTML={{ __html: this.state.msg }} />
        </div>
      )
    }
    return (
      <form {...props}>
        <input
          type="email"
          name="email"
          placeholder="Subscribe to Newsletter"
          onChange={this._handleChange}
          required
        />
        <button 
          type="submit" 
          className="btn"
          onSubmit={this._handleSubmit}
          >
          <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.064 3.424L5.148 0.508L5.628 0.0399995L9.36 3.772L5.628 7.504L5.136 7.036L8.064 4.12H0.468V3.424H8.064Z" fill="white"/>
          </svg>
        </button>
      </form>
    );
  }
}

export default NewsletterForm